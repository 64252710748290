import lodashFindKey from 'lodash-es/findKey';
import type { ComputedRef, Ref } from 'vue';
import type { RouteParams } from 'vue-router';

import { AssetType, BusClass } from '~/apiClient';
import { Locale } from '~/i18n/i18nConfig';

export type OptionalParams = {
    localizedClass: string | undefined;
    brandUrlKey: string | undefined;
    modelUrlKey: string | undefined;
};

type OptionalParamKey = keyof OptionalParams;

type ParsedRouteParams =
    | ({
          isFormallyValid: false;
          assetType: undefined;
          assetClass?: undefined;
      } & Record<OptionalParamKey, undefined>)
    | ({
          isFormallyValid: true;
          assetType: AssetType;
          assetClass?: BusClass;
      } & Record<OptionalParamKey, string | undefined>);

type LocalizedAssetTypes = Record<AssetType, string>;
type LocalizedClasses = Record<AssetType, Record<BusClass, string>>;

interface UseAlpRouteParamsParserReturn {
    parseRouteParams: (routeParams: RouteParams) => void;
    parsedRouteParams: Ref<ParsedRouteParams>;
    isRouteFormallyValid: ComputedRef<boolean>;
    localizedAssetTypes: ComputedRef<LocalizedAssetTypes>;
    localizedClasses: ComputedRef<LocalizedClasses>;
}

export const optionalParamsOrder: OptionalParamKey[] = ['localizedClass', 'brandUrlKey', 'modelUrlKey'];

export const optionalParamsLetter: Record<OptionalParamKey, string> = {
    localizedClass: 'c',
    brandUrlKey: 'b',
    modelUrlKey: 'm',
};

export const localizedParams = {
    [Locale.EN]: {
        assetType: {
            [AssetType.Bus]: 'bus',
        },
        class: {
            [AssetType.Bus]: {
                [BusClass.ClassOne]: 'city',
                [BusClass.ClassTwo]: 'intercity',
                [BusClass.ClassThree]: 'sightseeing',
                [BusClass.ClassA]: 'a-class',
                [BusClass.ClassB]: 'b-class',
                [BusClass.SchoolBus]: 'school',
            },
        },
    },
    [Locale.IT]: {
        assetType: {
            [AssetType.Bus]: 'autobus',
        },
        class: {
            [AssetType.Bus]: {
                [BusClass.ClassOne]: 'urbano',
                [BusClass.ClassTwo]: 'interurbano',
                [BusClass.ClassThree]: 'turistico',
                [BusClass.ClassA]: 'classe-a',
                [BusClass.ClassB]: 'classe-b',
                [BusClass.SchoolBus]: 'scuolabus',
            },
        },
    },
    [Locale.ES]: {
        assetType: {
            [AssetType.Bus]: 'autobus',
        },
        class: {
            [AssetType.Bus]: {
                [BusClass.ClassOne]: 'urbano',
                [BusClass.ClassTwo]: 'interurbano',
                [BusClass.ClassThree]: 'turistico',
                [BusClass.ClassA]: 'clase-a',
                [BusClass.ClassB]: 'clase-b',
                [BusClass.SchoolBus]: 'escolar',
            },
        },
    },
};

export const useAlpRouteParamsParser = (): UseAlpRouteParamsParserReturn => {
    const locale = useNuxtApp().$i18n.locale;

    const localizedAssetTypes = computed(() => localizedParams[locale.value as Locale].assetType);

    const localizedClasses = computed(() => localizedParams[locale.value as Locale].class);

    const getParsedRouteParamsInitialData = (): ParsedRouteParams => ({
        isFormallyValid: false,
        assetType: undefined,
        assetClass: undefined,
        localizedClass: undefined,
        brandUrlKey: undefined,
        modelUrlKey: undefined,
    });

    const parsedRouteParams = useState<ParsedRouteParams>('alpParsedRouteParams', getParsedRouteParamsInitialData);

    const parseRouteParams = (routeParams: RouteParams): void => {
        parsedRouteParams.value = getParsedRouteParamsInitialData();
        const { localizedAssetType, optionalParams } = routeParams;
        const assetType = lodashFindKey(localizedAssetTypes.value, value => value === (localizedAssetType as string)) as AssetType | undefined;
        if (!assetType) return;

        if (optionalParams === '') {
            parsedRouteParams.value.isFormallyValid = true;
            parsedRouteParams.value.assetType = assetType;

            return;
        }

        const lastSegment: string | undefined = optionalParams?.at(-1);
        if (!Array.isArray(optionalParams) || optionalParams.length < 2 || lastSegment?.length !== optionalParams.length - 1) return;

        let lastIndex: number | null = null;
        const tmpOptionalParams: Record<OptionalParamKey, string | undefined> = {
            localizedClass: undefined,
            brandUrlKey: undefined,
            modelUrlKey: undefined,
        };

        for (let i = 0; i < lastSegment.length; i++) {
            const currentLetter = lastSegment[i];
            const currentIndex = optionalParamsOrder.map(param => optionalParamsLetter[param]).findIndex(param => param === currentLetter);
            if (currentIndex < 0 || (lastIndex && currentIndex <= lastIndex)) return;
            const paramKey = optionalParamsOrder[currentIndex];

            if (paramKey === 'localizedClass' && !Object.values(localizedClasses.value[assetType]).includes(optionalParams[i])) return;

            tmpOptionalParams[paramKey] = optionalParams[i];
            lastIndex = currentIndex;
        }

        if (tmpOptionalParams.modelUrlKey && !tmpOptionalParams.brandUrlKey) return;

        parsedRouteParams.value = {
            ...tmpOptionalParams,
            assetType,
            isFormallyValid: true,
            assetClass: tmpOptionalParams.localizedClass
                ? (lodashFindKey(localizedClasses.value[assetType], c => c === tmpOptionalParams.localizedClass) as BusClass)
                : undefined,
        };
    };

    return {
        parseRouteParams,
        parsedRouteParams,
        isRouteFormallyValid: computed(() => parsedRouteParams.value.isFormallyValid),
        localizedAssetTypes,
        localizedClasses,
    };
};
