import datetimeFormatEn from '~/i18n/datetime/en';
import en from '~/i18n/locales/en';
import numberFormatEn from '~/i18n/number/en';

interface INestedMessages {
    [key: string | number]: string | INestedMessages;
}

export type KeyPaths<ObjectType extends INestedMessages> = {
    [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends INestedMessages
        ? `${Key}` | `${Key}.${KeyPaths<ObjectType[Key]>}`
        : `${Key}`;
}[keyof ObjectType & (string | number)];

export type TranslationKey = KeyPaths<typeof en>;

export type TypedTranslateReturn = ReturnType<ReturnType<typeof useTypedI18n>['tt']>;

interface TypedT<TNamed, TDefaultMsg, TResult> {
    (key: TranslationKey): TResult;
    (key: TranslationKey, choice: number): TResult;
    (key: TranslationKey, named: TNamed): TResult;
    (key: TranslationKey, named: TNamed, defaultMsg: TDefaultMsg): TResult;
    (key: TranslationKey, list: unknown[]): TResult;
}

export type DatetimeFormatKey = keyof typeof datetimeFormatEn;

interface TypedD<TValue, TKeyOrOptions, TLocales, TResult> {
    (value: TValue): TResult;
    (value: TValue, keyOrOptions: DatetimeFormatKey | TKeyOrOptions): TResult;
    (value: TValue, keyOrOptions: DatetimeFormatKey | TKeyOrOptions, locale: TLocales): TResult;
}

export type NumberFormatKey = keyof typeof numberFormatEn;

interface TypedN<TValue, TKeyOrOptions, TLocales, TResult> {
    (value: TValue): TResult;
    (value: TValue, keyOrOptions: NumberFormatKey | TKeyOrOptions): TResult;
    (value: TValue, keyOrOptions: NumberFormatKey | TKeyOrOptions, locale: TLocales): TResult;
}

interface TypedTe<Str, Key> {
    (key: Str | Key, locale?: string): boolean;
}

interface FormatFunction {
    (value: number): string;
    (value: number | null): string | null;
}

type FormatUnits = {
    km: FormatFunction;
    meters: FormatFunction;
    cubicMeters: FormatFunction;
    kw: FormatFunction;
    kwh: FormatFunction;
    cc: FormatFunction;
    liters: FormatFunction;
    ampere: FormatFunction;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useTypedI18n = () => {
    // Needed to prevent error on plugins/middlewares see: https://github.com/intlify/vue-i18n/blob/dc83650c6059d22f05aca1475e9a80fd8aff7c72/packages/vue-i18n-core/src/i18n.ts#L699C3-L700C26
    const { t, d, n, te } = getCurrentInstance() ? useI18n() : useNuxtApp().$i18n;

    const tt = t as TypedT<Parameters<typeof t>[1], Parameters<typeof t>[2], ReturnType<typeof t>>;
    const td = d as TypedD<Parameters<typeof d>[0], Parameters<typeof d>[1], Parameters<typeof d>[2], ReturnType<typeof d>>;
    const tn = n as TypedN<Parameters<typeof n>[0], Parameters<typeof n>[1], Parameters<typeof n>[2], ReturnType<typeof n>>;
    const tte = te as TypedTe<Parameters<typeof te>[0], Parameters<typeof n>[1]>;

    const makeUnitFormatter = (numberFormat: NumberFormatKey, unit: string): FormatFunction =>
        (value => (value === null ? null : `${tn(value, numberFormat)} ${unit}`)) as FormatFunction;

    const formatUnits: FormatUnits = {
        km: makeUnitFormatter('integer', 'km'),
        meters: makeUnitFormatter('decimal', 'm'),
        cubicMeters: makeUnitFormatter('optionalDecimal', '\u33A5'),
        kw: makeUnitFormatter('optionalDecimal', 'kW'),
        kwh: makeUnitFormatter('optionalDecimal', 'kWh'),
        cc: makeUnitFormatter('optionalDecimal', 'cc'),
        liters: makeUnitFormatter('integer', 'L'),
        ampere: makeUnitFormatter('integer', 'A'),
    };

    return {
        tt,
        td,
        tn,
        tte,
        formatUnits,
    };
};
